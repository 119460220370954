// =====================================================
// Page load effects
// =====================================================

(function loadingPage() {
  // page loading
  function init() {
    document.body.classList.remove('loading');
  }
  setTimeout(init, 1000);

  window.onload = function () {
    document.body.classList.remove('loading');
  };
}());
