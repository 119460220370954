// =====================================================
// Mobile menu toggle
// =====================================================
(function toggleMenu() {
  const trigger = document.querySelector('#menu-trigger');
  const body = document.querySelector('body');
  trigger.addEventListener('click', openMenu);

  function openMenu() {
    trigger.removeEventListener('click', openMenu);
    body.classList.add('menu-open');
    trigger.addEventListener('click', closeMenu);
    // window.addEventListener('resize', closeMenu);
  }
  function closeMenu() {
    trigger.removeEventListener('click', closeMenu);
    body.classList.remove('menu-open');
    trigger.addEventListener('click', openMenu);
    // window.removeEventListener('resize', closeMenu);
  }
}());

// =====================================================
// Mobile menu submenu
// =====================================================
(function toggleSubmenu() {
  const ulMenu = document.querySelectorAll('#mobile-menu .menu-item');
  const anwalte = document.querySelector('#mobile-menu .haschild a');
  anwalte.addEventListener('click', openSubmenu);

  function openSubmenu() {
    anwalte.removeEventListener('click', openSubmenu);
    for (const listItem of ulMenu) {
      listItem.classList.remove('is-active');
      listItem.classList.remove('submenu-open');
    }
    anwalte.parentElement.classList.add('submenu-open');
    anwalte.addEventListener('click', closeSubmenu);
  }
  function closeSubmenu() {
    anwalte.removeEventListener('click', closeSubmenu);
    anwalte.parentElement.classList.remove('is-active');
    anwalte.parentElement.classList.remove('submenu-open');
    anwalte.addEventListener('click', openSubmenu);
  }
}());
